require('./upload-menu.scss')
/** @ngInject */
function UploadMenuDialogController ($scope, $translate, $mdDialog) {
  $scope.selectMethod = function selectMethod (method = 'files') {
    $mdDialog.hide({ method })
  }

  /**
   * Initialization function
   */
  const initDialog = async function initDialog () {
    $scope.options = [
      {
        code: 'files',
        icon: 'icon-file-upload',
        title: $translate.instant('FM.FILES')
      },
      {
        code: 'camera',
        icon: 'icon-camera',
        title: $translate.instant('FM.CAMERA')
      }
      // {
      //   code: 'video',
      //   icon: 'icon-video',
      //   title: $translate.instant('FM.VIDEO')
      // }
    ]
  }

  initDialog()
}
module.exports = UploadMenuDialogController
