export const tinymceConfigValue = {
  baseURL: '/tinymce',
  toolbar_mode: 'floating',
  contextmenu: 'link useBrowserSpellcheck table',
  relative_urls: false,
  paste_preprocess: function (editor, args) {
    if (
      args.content.includes('mso-') ||
      args.content.includes('MsoListParagraphCxSpLast') ||
      args.content.includes('MsoListParagraphCxSpFirst') ||
      args.content.includes('</v:shape') ||
      args.content.includes('MsoTableGrid') ||
      args.content.includes('<o:p></o:p>') ||
      args.content.includes('MsoNormal') ||
      args.content.includes('v:imagedata')
    ) {
      // show editor warning
      editor.notificationManager.open({
        text: 'Pasting from Microsoft Word is not supported. Please paste as plain text using `Ctrl+Shift+V`.',
        type: 'error',
        timeout: 1000 * 5
      })
      return args.preventDefault()
    } else {
      return args.content
    }
  },

  browser_spellcheck: true,
  menu: {
    file: {
      title: 'File',
      items: 'print'
    },
    edit: {
      title: 'Edit',
      items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'
    },
    view: {
      title: 'View',
      items:
        'code | visualaid visualchars visualblocks | spellchecker | preview'
    },
    insert: {
      title: 'Insert',
      items:
        'nesteditem image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime'
    },
    format: {
      title: 'Format',
      items:
        'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat'
    },
    tools: {
      title: 'Tools',
      items: 'code wordcount'
    }
    // table: {
    //   title: 'Table',
    //   items:
    //     'inserttable | cell row column | advtablesort | tableprops deletetable'
    // },
    // help: { title: 'Help', items: 'help' }
  },
  extended_valid_elements:
    'img[class|src|ta-insert-video|border=0|alt|title|hspace|vspace|width|height|align|name]',
  branding: false,
  promotion: false,
  resize: true,
  width: '100%'
}
module.exports = tinymceConfigValue
