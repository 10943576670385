/* global angular _ */
import { convertFromCustomFieldDialogObject } from '../services/FormlyConverter'
import { move, removeUnnecessaryColumns } from 'app/helper'
const debug = require('debug')('nextplus:fields')

/** @ngInject */
function WorkflowCustomFieldsDialogController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  $mdToast,
  locals,
  Field,
  ResolvedTables,
  ResolveFileParser,
  FormlyHelper,
  PartAssembly,
  FieldUtilsService,
  IntercomService,
  Resource
) {
  $scope.Field = Field
  $scope.array = []
  $scope.livePreviewModel = {}
  $scope.disableEditUsedFieldsSetting =
    $rootScope.appSettings.disableEditUsedFields

  const mdToastError = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: 'document.body',
    theme: 'error-toast',
    hideDelay: 3000
  })

  const fileParsers = ResolveFileParser

  fileParsers.unshift({ id: null, name: 'Auto' })

  const changeTranslationData = function changeTranslationData (
    property,
    value
  ) {
    if (!_.isUndefined($scope.customFieldModel.translations)) {
      Object.keys($scope.customFieldModel.translations).forEach(language => {
        if (
          !_.isUndefined(
            $scope.customFieldModel.translations[language] &&
              !_.isUndefined(
                $scope.customFieldModel.translations[language][property]
              )
          )
        ) {
          $scope.customFieldModel.translations[language][property] = value
        }
      })
    }
  }

  const defaultValuesFields = [
    // DEFAULT VALUE
    {
      key: 'defaultValue',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant('Field.DIALOG.FORM.DEFAULT_VALUE'),
        onChange: function (value, options) {
          if (
            options &&
            options.templateOptions &&
            options.templateOptions.type &&
            options.templateOptions.type === 'number'
          ) {
            value = parseFloat(options.value())
          }
          $scope.customFieldPreview[0].defaultValue = value
          setLivePreviewData($scope.customFieldPreview[0].type)
        }
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return scope.model.type !== 'input' && scope.model.type !== 'textarea'
      },
      expressionProperties: {
        'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
          return $scope.isDisabled
            ? true
            : scope.fields[15].templateOptions.disabled
        },
        'templateOptions.type': ($viewValue, $modelValue, scope) => {
          return scope.model.type === 'input' ? scope.model.inputType : 'text'
        }
      }
    },
    // DEFAULT VALUE - SELECT AND RADIO
    {
      key: 'defaultValue',
      type: 'select',
      className: 'layout-row',
      templateOptions: {
        options: [{ id: 1, name: 'yakov' }],
        label: $translate.instant('Field.DIALOG.FORM.DEFAULT_VALUE'),
        onChange: function (value, options) {
          $scope.customFieldPreview[0].defaultValue = options.value()
          setLivePreviewData($scope.customFieldPreview[0].type)
        }
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return (
          scope.model.type !== 'radioMaterial' &&
          scope.model.type !== 'selectWithSearch'
        )
      },
      expressionProperties: {
        'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
          return $scope.isDisabled
            ? true
            : scope.fields[13].templateOptions.disabled
        },
        'templateOptions.options': ($viewValue, $modelValue, scope) => {
          const options = scope.model.options.map(option => ({
            value: option.id,
            name: option.name
          }))
          options.unshift({ value: null, name: null })
          return options
        }
      }
    },
    // DEFAULT VALUE - DATE
    {
      key: 'defaultValue',
      type: 'switch',
      className: 'layout-row',
      templateOptions: {
        label: $translate.instant('Field.DIALOG.FORM.DEFAULT_VALUE_NOW'),
        onChange: function (value, options) {
          if (options.value()) {
            $scope.customFieldPreview[0].defaultValue = new Date()
          } else {
            $scope.customFieldPreview[0].defaultValue = null
          }
          setLivePreviewData($scope.customFieldPreview[0].type)
        }
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return (
          scope.model.type !== 'datePicker' &&
          scope.model.type !== 'dateTimePicker'
        )
      },
      expressionProperties: {
        'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
          return $scope.isDisabled
            ? true
            : scope.fields[13].templateOptions.disabled
        }
      }
    }
  ]

  const availableEditTypes = {
    checkbox: ['checkbox'],
    upload: ['upload'],
    subForm: ['subForm'],
    inputNumber: ['input'],
    input: ['input', 'textarea'],
    textarea: ['input', 'textarea'],
    selectWithSearch: ['selectWithSearch', 'radioMaterial'],
    radioMaterial: ['selectWithSearch', 'radioMaterial'],
    datePicker: ['datePicker', 'dateTimePicker'],
    dateTimePicker: ['datePicker', 'dateTimePicker'],
    lookupSelect: ['lookupSelect'],
    imageBasedForm: ['imageBasedForm'],
    gpsInput: ['gpsInput'],
    selectUser: ['selectUser'],
    tinymce: ['tinymce']
  }

  const fieldsType = [
    {
      id: 'input',
      name: $translate.instant('Field.DIALOG.TYPES.INPUT'),
      icon: 'icon-format-text'
    },
    {
      id: 'radioMaterial',
      name: $translate.instant('Field.DIALOG.TYPES.RADIO'),
      icon: 'icon-radiobox-marked'
    },
    {
      id: 'selectWithSearch',
      name: $translate.instant('Field.DIALOG.TYPES.SELECT'),
      icon: 'icon-form-dropdown'
    },
    {
      id: 'checkbox',
      name: $translate.instant('Field.DIALOG.TYPES.CHECKBOX'),
      icon: 'icon-checkbox-marked-outline'
    },
    {
      id: 'textarea',
      name: $translate.instant('Field.DIALOG.TYPES.TEXT_AREA'),
      icon: 'icon-keyboard'
    },
    {
      id: 'upload',
      name: $translate.instant('Field.DIALOG.TYPES.UPLOAD_BUTTON'),
      icon: 'icon-upload'
    },
    {
      id: 'datePicker',
      name: $translate.instant('Field.DIALOG.TYPES.DATE_PICKER'),
      icon: 'icon-calendar'
    },
    {
      id: 'dateTimePicker',
      name: $translate.instant('Field.DIALOG.TYPES.DATE_TIME_PICKER'),
      icon: 'icon-calendar-clock'
    },
    {
      id: 'subForm',
      name: $translate.instant('Field.DIALOG.TYPES.SUB_FORM'),
      icon: 'icon-view-list-outline'
    },
    {
      id: 'lookupSelect',
      name: $translate.instant('Field.DIALOG.TYPES.LOOKUP_SELECT'),
      icon: 'icon-database-search'
    },
    {
      id: 'imageBasedForm',
      name: $translate.instant('Field.DIALOG.TYPES.IMAGE_BASED_FORM'),
      icon: 'icon-image-edit-outline'
    },
    {
      id: 'gpsInput',
      name: $translate.instant('Field.DIALOG.TYPES.LOCATION'),
      icon: 'icon-map-marker-radius'
    },
    {
      id: 'selectUser',
      name: $translate.instant('Field.DIALOG.TYPES.SELECT_USER'),
      icon: 'icon-account-multiple'
    },
    {
      id: 'tinymce',
      name: $translate.instant('Field.DIALOG.TYPES.RICH_TEXT'),
      icon: 'icon-format-text'
    }
  ]

  const subTypeOptions = [
    {
      value: 'text',
      name: $translate.instant('Field.DIALOG.FORM.TYPE_TEXT')
    },
    {
      value: 'number',
      name: $translate.instant('Field.DIALOG.FORM.TYPE_NUMBER')
    }
  ]

  const changeDefaultValueObject = function changeDefaultValueObject (type) {
    let defaultValueIndex = 0
    switch (type) {
      case 'selectWithSearch':
      case 'radioMaterial':
        defaultValueIndex = 1
        break
      case 'datePicker':
      case 'dateTimePicker':
        defaultValueIndex = 2
        break
      default:
        defaultValueIndex = 0
    }
    if (
      $scope.currentDefaultValueIndex === null ||
      $scope.currentDefaultValueIndex !== defaultValueIndex
    ) {
      $scope.currentDefaultValueIndex = defaultValueIndex
      $scope.formFields[15] = $scope.currentDefaultValueObject =
        defaultValuesFields[defaultValueIndex]
    }
  }

  /**
   * Rerendering input fields
   * @param {string} type custom field type
   * @param {boolean} clear if clear all options in repeating section - defualt false
   */
  const setLivePreviewData = function setLivePreviewData (type, clear = false) {
    const lastField = $scope.customFieldPreview[0]
    changeDefaultValueObject(type)
    const generatedKey = 'temp' + Math.floor(Math.random() * 1000)
    if ((type === 'radioMaterial' || type === 'selectWithSearch') && clear) {
      if (type === 'radioMaterial') {
        lastField.templateOptions.layout = 'column'
      } else {
        lastField.templateOptions.staticLabel = true
      }
      lastField.templateOptions.placeholder = ''
      $scope.customFieldModel.placeholder = ''
      if (!lastField.templateOptions.options) {
        lastField.templateOptions.options = []
      } else if (type === 'radioMaterial') {
        lastField.templateOptions.options =
          lastField.templateOptions.options.map(option => ({
            value: option.id || option.value,
            name: option.name,
            resourceId: option.resourceId || null
          }))
      } else {
        lastField.templateOptions.options =
          lastField.templateOptions.options.map(option => ({
            id: option.id || option.value,
            name: option.name,
            resourceId: option.resourceId || null
          }))
      }
      lastField.expressionProperties = {
        'templateOptions.options': ($viewValue, $modelValue, scope) => {
          return scope.model.options
            ? scope.model.options.map(option => ({
                id: option.id,
                value: option.id,
                name: option.name,
                resourceId: option.resourceId
              }))
            : []
        }
      }
      changeTranslationData('placeholder', '')
      lastField.className = 'layout-column'
    } else if (type === 'textarea') {
      lastField.className = 'layout-column'
      lastField.templateOptions.rows = 3
    } else if (type === 'upload') {
      lastField.className = 'layout-column'
      lastField.templateOptions.displaytype = 'upload'
    } else if (type === 'checkbox') {
      lastField.className = 'layout-column'
    }
    if (type !== 'upload' && type !== 'subForm' && type !== 'imageBasedForm') {
      lastField.templateOptions.caption = ''
      $scope.customFieldModel.caption = ''
      changeTranslationData('caption', '')
    }
    if (type !== 'radioMaterial' && type !== 'selectWithSearch') {
      lastField.templateOptions.options = []
      $scope.customFieldModel.options = []
      $scope.livePreviewModel.options = []
      changeTranslationData('options', [])
    }
    if (type !== 'datePicker' && type !== 'dateTimePicker') {
      lastField.templateOptions.minDate = lastField.templateOptions.maxDate =
        null
      $scope.customFieldModel.minDate = $scope.customFieldModel.maxDate = null
    }
    if (type === 'subForm' && clear) {
      lastField.templateOptions.editable = true
      lastField.templateOptions.caption = 'Add'
      $scope.customFieldModel.caption = 'Add'
      lastField.templateOptions.fields = lastField.templateOptions.fields || []
    }

    if (type !== 'imageBasedForm') {
      $scope.customFieldModel.resourceId = null
      delete lastField.templateOptions.images
      delete lastField.templateOptions.preClickEvent
    } else {
      lastField.templateOptions.preClickEvent = () => {
        const { layers, shapes } = $scope.mapFunctions.getMap()
        return {
          fieldIds: $scope.customFieldModel.fieldIds,
          layers,
          shapes
        }
      }
    }

    if (type === 'imageBasedForm' || type === 'subForm') {
      delete lastField.templateOptions.helpImage
      delete $scope.customFieldModel.helpImage
    }

    if (type === 'modelSelect') {
      lastField.templateOptions.findMethod = PartAssembly.find
      lastField.templateOptions.mapObject = {
        id: 'number',
        name: 'number',
        displayName: 'name'
      }
      lastField.templateOptions.baseFilterObject = {
        where: {},
        fields: {
          number: true,
          name: true
        }
      }
    }

    $scope.customFieldPreview = [
      {
        name: lastField.name,
        key: generatedKey,
        type,
        hide: false,
        className: lastField.className,
        templateOptions: lastField.templateOptions,
        expressionProperties: lastField.expressionProperties || null
      }
    ]
    if (!clear && !_.isNull(lastField.defaultValue)) {
      $scope.customFieldPreview[0].defaultValue = lastField.defaultValue
    } else {
      $scope.customFieldModel.defaultValue = null
    }
    $scope.fieldValid = true
    $scope.$applyAsync()
  }

  /**
   * Create new option in preview form
   * @param {string} id option id
   */
  const addNewOption = function addNewOption (id) {
    const optionsArray = $scope.livePreviewModel.options || []
    if (_.isUndefined(_.find(optionsArray, { id }))) {
      optionsArray.push({
        id,
        value: id,
        name: '',
        resourceId: null
      })
    }
    $scope.livePreviewModel.options = optionsArray
  }

  /**
   * Remove option in preview form by index
   * @param {number} index index in option array
   */
  const removeOption = function removeOption (index) {
    const options = $scope.livePreviewModel.options
    options.splice(index, 1)
    // handle edit mode
    if (options.length !== $scope.array.length) {
      $scope.array.splice(index, 1)
    }
    if (
      $scope.customFieldModel.options &&
      $scope.customFieldModel.options.length > options.length
    ) {
      $scope.customFieldModel.options.splice(index, 1)
    }
    if (!_.isUndefined($scope.customFieldModel.translations)) {
      Object.keys($scope.customFieldModel.translations).forEach(language => {
        const languageTranslation =
          $scope.customFieldModel.translations[language]
        if (
          languageTranslation.options &&
          languageTranslation.options.length > 0
        ) {
          languageTranslation.options.splice(index, 1)
        }
      })
    }
  }

  /**
   * Change option position in preview form
   * @param {number} index index in option array
   * @param {string} propertyName value for change
   * @param {boolean} moveUp replace upper or bottom cell index
   */
  const moveButton = function moveButton (index, propertyName, moveUp) {
    let moveIndex = index
    moveUp ? moveIndex-- : moveIndex++
    if (!_.isUndefined($scope.customFieldModel.translations)) {
      Object.keys($scope.customFieldModel.translations).forEach(language => {
        const languageTranslation =
          $scope.customFieldModel.translations[language]
        if (
          languageTranslation.options &&
          languageTranslation.options.length > 0
        ) {
          const previousName = languageTranslation.options[index][propertyName]
          languageTranslation.options[index][propertyName] =
            languageTranslation.options[moveIndex][propertyName]
          languageTranslation.options[moveIndex][propertyName] = previousName
        }
      })
    }
    const optionsArray = $scope.livePreviewModel.options
    const previousName = optionsArray[index][propertyName]
    optionsArray[index][propertyName] = optionsArray[moveIndex][propertyName]
    optionsArray[moveIndex][propertyName] = previousName
    $scope.$applyAsync()
  }

  const propertyIsNillOrEmpty = function propertyIsNillOrEmpty (property) {
    return _.isNil(property) || _.isEmpty(property)
  }

  /**
   * Check that user filled up all required fields
   * @param {object} item custom field object
   */
  const checkValidation = function checkValidation (item) {
    let valid = !propertyIsNillOrEmpty(item.title)
    if (!valid) return { valid: false, msg: 'MISSING_FIELD_TITLE' }
    let msg = 'FIELD_VALIDATION_ERROR_MESSAGE'
    switch (item.type) {
      case 'input':
        if (propertyIsNillOrEmpty(item.inputType)) {
          valid = false
          msg = 'SUB_TYPE_IS_REQUIRED'
        }
        break
      case 'textarea':
        break
      case 'checkbox':
        break
      case 'radioMaterial':
      case 'selectWithSearch':
        if (
          propertyIsNillOrEmpty(item.options) ||
          item.options.some(option => propertyIsNillOrEmpty(option.name))
        ) {
          valid = false
        } else if (
          _.uniqBy(item.options, 'name').length !== item.options.length
        ) {
          valid = false
          msg = 'NON_UNIQUE_OPTIONS'
        }
        break
      case 'datePicker':
      case 'dateTimePicker':
        if (
          valid === true &&
          !propertyIsNillOrEmpty(item.minDate) &&
          !propertyIsNillOrEmpty(item.maxDate) &&
          item.minDate > item.maxDate
        ) {
          valid = false
          msg = 'MINDATE_BIGGER_THAN_MAXDATE'
        }
        break
      case 'lookupSelect':
        if (propertyIsNillOrEmpty(item.tableId)) {
          valid = false
          msg = 'NO_TABLE_SELECTED'
        } else if (item.tableType === FieldUtilsService.lookupTypes.NPT) {
          if (propertyIsNillOrEmpty(item.lookupField)) {
            valid = false
            msg = 'NO_LOOKUP_FIELD_SELECTED'
          } else if (propertyIsNillOrEmpty(item.fieldIds)) {
            valid = false
            msg = 'NO_COLUMNS_SELECTED'
          }
        }
        break
      case 'subForm':
        if (propertyIsNillOrEmpty(item.fields)) {
          valid = false
          msg = 'NO_FIELDS_SELECTED'
        } else if (propertyIsNillOrEmpty(item.caption)) {
          valid = false
          msg = 'BUTTON_TEXT_IS_REQUIRED'
        } else if (_.isNil(item.minRequiredRows) || item.minRequiredRows < 0) {
          valid = false
          msg = 'INVALID_MIN_REQUIRED_ROWS'
        }
        break
      case 'imageBasedForm':
        if (propertyIsNillOrEmpty(item.fields)) {
          valid = false
          msg = 'NO_FIELDS_SELECTED'
        } else if (propertyIsNillOrEmpty(item.resourceId)) {
          valid = false
          msg = 'NO_RESOURCE_SELECTED'
        } else if (propertyIsNillOrEmpty(item.layers)) {
          valid = false
          msg = 'NO_LAYERS_DETECTED'
        } else if (
          item.layers.some(layer => propertyIsNillOrEmpty(layer.name))
        ) {
          valid = false
          msg = 'LAYER_WITHOUT_NAME'
        } else if (propertyIsNillOrEmpty(item.shapes)) {
          valid = false
          msg = 'NO_SHAPES_DETECTED'
        }
        break
    }

    return { valid, msg }
  }

  const fillMissingFieldProperties = function fillMissingFieldProperties (
    field
  ) {
    if (field.type === 'imageBasedForm') {
      const { layers, shapes } = $scope.mapFunctions.getMap()
      field.shapes = shapes
      field.layers = layers
    }
    return _.cloneDeep(field)
  }

  /**
   * Create new field in database
   */
  $scope.addField = function addField () {
    const item = fillMissingFieldProperties($scope.customFieldModel)
    const { valid, msg } = checkValidation(item)
    if (valid) {
      const convertedField = convertFromCustomFieldDialogObject(item)
      Field.create(convertedField)
        .$promise.then(data => {
          $mdDialog.hide(data)
          IntercomService.trackEvent('field-create')
        })
        .catch(e => {
          $mdToast.show(mdToastError)
          $mdToast.updateTextContent(
            $translate.instant('Field.DIALOG.SAVE_ERROR_MESSAGE')
          )
        })
    } else {
      $mdToast.show(mdToastError)
      $mdToast.updateTextContent($translate.instant(`Field.DIALOG.${msg}`))
    }
  }

  /**
   * Save changes in current field
   */
  $scope.saveField = function saveField () {
    const item = fillMissingFieldProperties($scope.customFieldModel)
    const { valid, msg } = checkValidation(item)
    if (valid) {
      const convertedField = convertFromCustomFieldDialogObject(item)
      Field.prototype$patchAttributes({ id: locals.field.id }, convertedField)
        .$promise.then(data => {
          $mdDialog.hide(data)
        })
        .catch(e => {
          let message = $translate.instant('Field.DIALOG.SAVE_ERROR_MESSAGE')
          if (e?.data?.error?.code === 'FIELD_IN_USE') {
            message = $translate.instant('Field.DIALOG.FIELD_IN_USE')
          }
          $mdToast.show(mdToastError)
          $mdToast.updateTextContent(message)
        })
    } else {
      $mdToast.show(mdToastError)
      $mdToast.updateTextContent($translate.instant(`Field.DIALOG.${msg}`))
    }
  }

  const fixTranslation = function fixTranslation (field) {
    if (field.translations) {
      const languages = Object.keys(field.translations)
      languages.forEach(lang => {
        const langValues = field.translations[lang]
        Object.keys(langValues).forEach(property => {
          if (langValues[property] && !_.isEmpty(langValues[property])) {
            if (
              locals.field[property] &&
              locals.field[property] === langValues[property]
            ) {
              if (property === 'options') {
                langValues[property] = []
              } else {
                langValues[property] = ''
              }
            }
          }
        })
      })
    }
    return field
  }

  /**
   * Save As field - create new field
   */
  $scope.saveAsField = function saveAsField (replace = false) {
    const item = fillMissingFieldProperties($scope.customFieldModel)
    const { valid, msg } = checkValidation(item)
    if (valid) {
      const convertedField = fixTranslation(
        _.omit(convertFromCustomFieldDialogObject(item), 'id')
      )
      Field.create(convertedField)
        .$promise.then(data => {
          const res = data
          if (replace) {
            data.replace = true
          }
          $mdDialog.hide(res)
        })
        .catch(e => {
          $mdToast.show(mdToastError)
          $mdToast.updateTextContent(
            $translate.instant('Field.DIALOG.SAVE_ERROR_MESSAGE')
          )
        })
    } else {
      $mdToast.show(mdToastError)
      $mdToast.updateTextContent($translate.instant(`Field.DIALOG.${msg}`))
    }
  }

  /**
   * Close dialog
   */
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  const convertFormlyObjectToScreenFormat =
    function convertFormlyObjectToScreenFormat (formlyObject) {
      const obj = {}
      obj.id = formlyObject.key
      obj.key = formlyObject.key

      obj.type = formlyObject.type
      if (formlyObject.type === 'customInput') {
        obj.type = 'input'
      } else if (formlyObject.type === 'customTextarea') {
        obj.type = 'textarea'
      } else if (formlyObject.type === 'modelSelect') {
        obj.type = 'lookupSelect'
      }
      obj.title = formlyObject.templateOptions.label
      obj.caption = formlyObject.templateOptions.caption
      obj.helpText = formlyObject.templateOptions.hint
      obj.helpImage = formlyObject.templateOptions.helpImage
      obj.placeholder = formlyObject.templateOptions.placeholder
      obj.inputType = formlyObject.templateOptions.type
      obj.visionAi = formlyObject.templateOptions.visionAi
      obj.aiPrompt = formlyObject.templateOptions.aiPrompt
      obj.radioAlign = formlyObject.templateOptions.layout === 'row'
      obj.required = formlyObject.templateOptions.required
      obj.pattern = formlyObject.templateOptions.pattern
      obj.minDate = formlyObject.templateOptions.minDate
      obj.maxDate = formlyObject.templateOptions.maxDate
      obj.min = formlyObject.templateOptions.min
      obj.max = formlyObject.templateOptions.max
      obj.runFileParser = formlyObject.templateOptions.runFileParser || false
      obj.fileParser = formlyObject.templateOptions.fileParser || null
      obj.tableId = formlyObject.templateOptions.tableId
      obj.lookupField = formlyObject.templateOptions.lookupField
      obj.fields = formlyObject.templateOptions.originalFields || []
      obj.fieldIds = formlyObject.templateOptions.fieldIds || []
      obj.minRequiredRows = formlyObject.templateOptions.minRequiredRows || 0
      obj.tableType = formlyObject.templateOptions.tableType
      obj.lookupFieldType = formlyObject.templateOptions.lookupFieldType
      obj.multiple = formlyObject.templateOptions.multiple
      obj.mode = formlyObject.templateOptions.mode
      obj.defaultValue =
        formlyObject.type === 'datePicker' ||
        formlyObject.type === 'dateTimePicker'
          ? !!formlyObject.defaultValue
          : formlyObject.defaultValue
      obj.options = []
      if (
        formlyObject.templateOptions.images &&
        formlyObject.templateOptions.images.length
      ) {
        const { resourceId, id, layers, shapes } =
          formlyObject.templateOptions.images[0]
        obj.resourceId = resourceId || null
        obj.imageId = id || null
        obj.layers = layers || []
        obj.shapes = shapes || []
      }
      // translations
      obj.translations = _.cloneDeep(formlyObject.translations)
      // fallbackStatus
      obj.fallbackStatus = formlyObject.fallbackStatus
      const idsArray = []
      if (
        formlyObject.templateOptions.options &&
        formlyObject.templateOptions.options.length > 0
      ) {
        formlyObject.templateOptions.options.forEach(option => {
          const value = option.value || option.name
          idsArray.push(value)
          $scope.array.push({
            id: value,
            value,
            name: value,
            resourceId: option.resourceId || null
          })
        })
        obj.options = $scope.array
        $scope.livePreviewModel.options = JSON.parse(
          JSON.stringify($scope.array)
        )
        if (formlyObject.translations) {
          Object.keys(formlyObject.translations).forEach(language => {
            const languageData = formlyObject.translations[language]
            if (languageData.options && languageData.options.length > 0) {
              if (!obj.translations[language]) {
                obj.translations[language] = {}
              }
              obj.translations[language].options = []
              languageData.options.forEach((option, index) => {
                const value = option.value || option.name
                obj.translations[language].options.push({
                  id: idsArray[index],
                  value,
                  name: value,
                  resourceId: option.resourceId || null
                })
              })
            }
          })
        }
      }
      return obj
    }

  $scope.updatePreview = function updatePreview (language, defaultLanguage) {
    // Get all property from model then put them in preview
    const model = $scope.customFieldModel
    const previewObject = _.pick($scope.customFieldPreview[0], [
      'key',
      'type',
      'className',
      'templateOptions'
    ])
    if (defaultLanguage !== language) {
      const translation = model.translations[language]
      model.optionsDisabled = true
      if (!_.isUndefined(translation)) {
        previewObject.templateOptions.label = translation.title || ''
        previewObject.templateOptions.hint = translation.helpText || ''
        previewObject.templateOptions.placeholder =
          translation.placeholder || ''
        previewObject.templateOptions.caption = translation.caption || ''
        previewObject.templateOptions.options = translation.options || []
      } else {
        console.log('No translations available')
      }
    } else {
      model.optionsDisabled = false
      previewObject.templateOptions.label = model.title || ''
      previewObject.templateOptions.hint = model.helpText || ''
      previewObject.templateOptions.placeholder = model.placeholder || ''
      previewObject.templateOptions.caption = model.caption || ''
      previewObject.templateOptions.options = model.options || []
    }
    $scope.customFieldPreview = [previewObject]
    $scope.$applyAsync()
  }

  $scope.addEditFields = function addEditFields (selectedFields = []) {
    $mdDialog
      .show({
        controller: 'WorkflowCustomFieldsController',
        template: require('../edit/dialog/workflow.fields.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        resolve: {
          ResolvedData: () => {
            return {
              ignoreFieldId: $scope.editMode ? locals.field.id : null,
              ignoreSubForms: true,
              selectedFields
            }
          }
        },
        clickOutsideToClose: false,
        escapeToClose: false
      })
      .then(
        async function (response) {
          if (response) {
            $scope.isLoading = true
            const { selectedFields, fields } = response
            const formlyFields = []
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].type !== 'subForm') {
                const formlyField = await FormlyHelper.convertToFormlyObject(
                  fields[i]
                )
                formlyFields.push(formlyField)
              } else {
                const idx = selectedFields.findIndex(id => fields[i].id === id)
                if (idx !== -1) {
                  selectedFields.splice(idx, 1)
                }
                fields.splice(i, 1)
                i--
              }
            }
            $scope.customFieldPreview[0].templateOptions.fields = formlyFields
            $scope.customFieldModel.fieldIds = selectedFields
            $scope.customFieldModel.fields = fields
            $scope.isLoading = false
            setLivePreviewData($scope.customFieldModel.type, true)
          }
        },
        function () {}
      )
  }

  const init = async function init () {
    debug('init')
    if (locals.removeSubForm === true) {
      _.remove(fieldsType, { id: 'subForm' })
    }
    $scope.editMode = !!locals.field
    $scope.fieldIsUsed = locals.fieldIsUsed
    $scope.selectedFileIncludes = []
    $scope.radioDirection = false
    $scope.isDisabled = locals.disabled || false
    $scope.lookupTableOptions = [
      {
        group: FieldUtilsService.lookupTypes.MODELS,
        id: 'PartAssembly',
        name: $translate.instant('Field.DIALOG.MODEL_OPTIONS.PARTS')
      }
    ]
    $scope.tablesLookupByOptions = {}
    $scope.tablesFieldsOptions = {}
    $scope.editingLayer = false
    $scope.mapFunctions = {
      onStartEditLayer: () => {
        $scope.editingLayer = true
      },
      onFinishEditLayer: () => {
        $scope.editingLayer = false
      }
    }
    $scope.shapeOptions = ['polygon', 'circle']
    for (let i = 0; i < ResolvedTables.length; i++) {
      const table = ResolvedTables[i]
      $scope.lookupTableOptions.push({
        group: FieldUtilsService.lookupTypes.NPT,
        id: table.id,
        name: table.name
      })
      const columns = removeUnnecessaryColumns(table.columns).filter(
        col => !col.deletedAt
      )
      $scope.tablesFieldsOptions[table.id] = _.cloneDeep(columns).filter(
        col => col.type !== 'resource'
      )
      $scope.tablesLookupByOptions[table.id] = columns.filter(
        col =>
          col.type !== 'boolean' &&
          col.type !== 'date' &&
          col.type !== 'resource'
      )
    }
    $scope.currentDefaultValueObject = defaultValuesFields[0]
    $scope.currentDefaultValueIndex = 0
    if (locals.field) {
      debug('selected field', locals.field)
      $scope.isLoading = true
      const formlyObject = await FormlyHelper.convertToFormlyObject(
        locals.field
      )
      $scope.customFieldModel = convertFormlyObjectToScreenFormat(formlyObject)

      $scope.customFieldPreview = [formlyObject]
      changeDefaultValueObject(formlyObject.type)
      if (
        formlyObject.type === 'radioMaterial' ||
        formlyObject.type === 'selectWithSearch'
      ) {
        formlyObject.expressionProperties = {
          'templateOptions.options': ($viewValue, $modelValue, scope) => {
            return scope.model.options
              ? scope.model.options.map(option => ({
                  id: option.id,
                  value: option.id,
                  name: option.name,
                  resourceId: option.resourceId || null
                }))
              : []
          }
        }
      }
      if (
        formlyObject.type === 'radioMaterial' &&
        formlyObject.templateOptions.layout === 'row'
      ) {
        $scope.radioDirection = true
      } else if (formlyObject.type === 'selectWithSearch') {
        formlyObject.templateOptions.staticLabel = true
      }
      const { result, shouldDisableFieldEdit } = await Field.getFieldIncluded({
        id: locals.field.id
      }).$promise
      $scope.selectedFileIncludes = result
      $scope.isLoading = false
      if ($scope.selectedFileIncludes.length > 0) {
        const availableTypes = availableEditTypes[$scope.customFieldModel.type]

        if (availableTypes && availableTypes.length) {
          if (
            $scope.customFieldModel.type === 'input' &&
            $scope.customFieldModel.inputType === 'number'
          ) {
            _.remove(subTypeOptions, { value: 'text' })
            _.remove(
              fieldsType,
              fieldType =>
                !availableEditTypes.inputNumber.includes(fieldType.id)
            )
          } else {
            if (
              $scope.customFieldModel.type === 'input' &&
              $scope.customFieldModel.inputType === 'text'
            ) {
              _.remove(subTypeOptions, { value: 'number' })
            }
            _.remove(
              fieldsType,
              fieldType => !availableTypes.includes(fieldType.id)
            )
          }
        }
        if ($scope.disableEditUsedFieldsSetting && shouldDisableFieldEdit) {
          $scope.isDisabled = true
        }
      }
      $scope.$applyAsync()
    } else {
      $scope.customFieldModel = {
        id: '',
        key: '',
        type: 'input',
        title: '',
        caption: '',
        helpText: '',
        helpImage: '',
        placeholder: '',
        resourceId: null,
        pattern: null,
        minDate: null,
        maxDate: null,
        tableType: null,
        lookupFieldType: null,
        tableId: null,
        options: $scope.array,
        inputType: 'text',
        visionAi: false,
        aiPrompt: '',
        radioAlign: false,
        required: false,
        runFileParser: false,
        fileParser: null,
        minRequiredRows: 0,
        layers: [],
        shapes: [],
        imageId: null,
        multiple: false,
        mode: 'full'
      }
      $scope.customFieldPreview = [
        {
          key: 'name',
          type: 'input',
          className: 'layout-row',
          templateOptions: {
            options: $scope.array
          }
        }
      ]
    }
    $scope.formFields = [
      // TYPE
      {
        key: 'type',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          focus: true,
          label: $translate.instant('Field.DIALOG.FORM.TYPE'),
          options: fieldsType,
          hint: $scope.editMode
            ? $translate.instant('Field.EDIT_TYPE_MESSAGE')
            : '',
          onChange: function (value, options) {
            setLivePreviewData(value, true)
          }
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[0].templateOptions.disabled
          }
        }
      },
      // TITLE
      {
        key: 'title',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          label: $translate.instant('Field.NAME'),
          required: true,
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.label = options.value()
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type === ''
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[1].templateOptions.disabled
          }
        }
      },
      // HELP-TEXT
      {
        key: 'helpText',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          label: $translate.instant('Field.DIALOG.FORM.HELP'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.hint = options.value()
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type === ''
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[2].templateOptions.disabled
          }
        }
      },
      // CAPTION : TEXT ON UPLOAD BUTTON
      {
        key: 'caption',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          label: $translate.instant('Field.DIALOG.FORM.CAPTION'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.caption =
              options.value()
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'upload' && scope.model.type !== 'subForm'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[3].templateOptions.disabled
          }
        }
      },
      // PLACEHOLDER
      {
        key: 'placeholder',
        type: 'input',
        hide: true,
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.PLACEHOLDER'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.placeholder =
              options.value()
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type !== 'input' &&
            scope.model.type !== 'textarea' &&
            scope.model.type !== 'dateTimePicker' &&
            scope.model.type !== 'datePicker' &&
            scope.model.type !== 'tinymce'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[4].templateOptions.disabled
          }
        }
      },
      // INPUT TYPE : TEXT / NUMBER
      {
        key: 'inputType',
        type: 'select',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.TYPE'),
          options: subTypeOptions,
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.type = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'input'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[5].templateOptions.disabled
          }
        }
      },
      // INPUT TYPE : TEXT / NUMBER
      {
        key: 'visionAi',
        type: 'checkbox',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.VISION_AI'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.type = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'input' && scope.model.type !== 'textarea'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[6].templateOptions.disabled
          }
        }
      },
      {
        key: 'aiPrompt',
        type: 'customTextarea',
        hide: true,
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.AI_PROMPT'),
          required: $scope.customFieldModel.visionAi,
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.aiPrompt = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            (scope.model.type !== 'input' && scope.model.type !== 'textarea') ||
            ((scope.model.type === 'input' ||
              scope.model.type === 'textarea') &&
              !scope.model.visionAi)
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[7].templateOptions.disabled
          }
        }
      },
      // RADIO BUTTON ALIGN DIRECTION
      {
        key: 'radioAlign',
        type: 'switch',
        hide: true,
        templateOptions: {
          label: $scope.radioDirection
            ? $translate.instant('Field.DIALOG.FORM.LAYOUT_HORIZONTAL')
            : $translate.instant('Field.DIALOG.FORM.LAYOUT_VERTICAL'),
          onClick: function (value, options) {
            if (value) {
              options.templateOptions.label = $translate.instant(
                'Field.DIALOG.FORM.LAYOUT_VERTICAL'
              )
              $scope.customFieldPreview[0].templateOptions.layout = 'column'
            } else {
              options.templateOptions.label = $translate.instant(
                'Field.DIALOG.FORM.LAYOUT_HORIZONTAL'
              )
              $scope.customFieldPreview[0].templateOptions.layout = 'row'
            }
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'radioMaterial'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[8].templateOptions.disabled
          }
        }
      },
      // OPTIONS : SELECT / RADIO BUTTONS
      {
        key: 'options',
        type: 'repeatingOptions',
        templateOptions: {
          btnText: $translate.instant('Field.DIALOG.FORM.ADD_OPTION'),
          onClick: function (id) {
            addNewOption(id)
          },
          onRemove: function (index) {
            removeOption(index)
          },
          onMoveUp: function (index) {
            moveButton(index, 'name', true)
          },
          onMoveDown: function (index) {
            moveButton(index, 'name', false)
          },
          fields: [
            {
              key: 'name',
              type: 'input',
              className: 'flex',
              templateOptions: {
                type: 'text',
                required: true,
                label: $translate.instant('Field.DIALOG.FORM.OPTION'),
                onChange: function (value, options) {
                  const index = options.templateOptions.index
                  const previewArray = $scope.livePreviewModel.options
                  if (previewArray[index]) {
                    previewArray[index].name = value
                  }
                }
              }
            },
            {
              key: 'resourceId',
              type: 'upload',
              className: 'layout-column',
              templateOptions: {
                keyIs: 'id',
                mode: 'full',
                filetype: 'image',
                object: 'resource',
                onChange: function (value, options) {
                  const index = options.templateOptions.index
                  const previewArray = $scope.livePreviewModel.options
                  if (previewArray[index]) {
                    previewArray[index].resourceId = value
                  }
                }
              }
            }
          ]
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type !== 'radioMaterial' &&
            scope.model.type !== 'selectWithSearch'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[9].templateOptions.disabled
          }
        }
      },
      // PATTERN
      {
        key: 'pattern',
        type: 'input',
        hide: true,
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.PATTERN'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.pattern =
              options.value()
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'input'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[10].templateOptions.disabled
          }
        }
      },
      // MIN_DATE
      {
        key: 'minDate',
        type: 'dateTimePicker',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.MIN_DATE'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.minDate = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'dateTimePicker'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[11].templateOptions.disabled
          }
        }
      },
      // MAX_DATE
      {
        key: 'maxDate',
        type: 'dateTimePicker',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.MAX_DATE'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.maxDate = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'dateTimePicker'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[12].templateOptions.disabled
          }
        }
      },
      // MIN_DATE
      {
        key: 'minDate',
        type: 'datePicker',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.MIN_DATE'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.minDate = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'datePicker'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[13].templateOptions.disabled
          }
        }
      },
      // MAX_DATE
      {
        key: 'maxDate',
        type: 'datePicker',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.MAX_DATE'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.maxDate = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'datePicker'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[14].templateOptions.disabled
          }
        }
      },
      $scope.currentDefaultValueObject,
      // TABLE
      {
        key: 'tableId',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          required: true,
          hasGroupedOptions: true,
          tooltip: $translate.instant('Field.DIALOG.FORM.LOOKUP_TABLE_TOOLTIP'),
          label: $translate.instant('Field.DIALOG.FORM.LOOKUP_TABLE'),
          options: $scope.lookupTableOptions,
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.tableId = value
            if (value === 'PartAssembly') {
              setLivePreviewData('modelSelect')
              $scope.customFieldModel.tableType =
                FieldUtilsService.lookupTypes.MODELS
              $scope.customFieldModel.lookupFieldType = 'string'
            } else {
              setLivePreviewData('lookupSelect')
              $scope.customFieldModel.tableType =
                FieldUtilsService.lookupTypes.NPT
            }
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type !== 'modelSelect' &&
            scope.model.type !== 'lookupSelect'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled ||
              ($scope.selectedFileIncludes &&
                $scope.selectedFileIncludes.length > 0)
              ? true
              : scope.fields[16].templateOptions.disabled
          }
        }
      },
      // LOOKUP_FIELD
      {
        key: 'lookupField',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          required: true,
          tooltip: $translate.instant('Field.DIALOG.FORM.LOOKUP_BY_TOOLTIP'),
          label: $translate.instant('Field.DIALOG.FORM.LOOKUP_BY'),
          options: [],
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.lookupField = value
            setLivePreviewData('lookupSelect')
            const column = $scope.tablesLookupByOptions[
              $scope.customFieldModel.tableId
            ].find(column => {
              return column.id === $scope.customFieldModel.lookupField
            })
            $scope.customFieldModel.lookupFieldType = column.type
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            !scope.model.tableId ||
            (scope.model.type !== 'lookupSelect' &&
              scope.model.type !== 'modelSelect') ||
            scope.model.tableType === FieldUtilsService.lookupTypes.MODELS
          )
        },
        expressionProperties: {
          'templateOptions.options': ($viewValue, $modelValue, scope) => {
            if (scope.model.tableId) {
              return $scope.tablesLookupByOptions[scope.model.tableId]
            }
            return []
          },
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled || $scope.selectedFileIncludes.length > 0
              ? true
              : scope.fields[17].templateOptions.disabled
          }
        }
      },
      // FIELDS_IDS
      {
        key: 'fieldIds',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          required: true,
          tooltip: $translate.instant(
            'Field.DIALOG.FORM.DISPLAY_COLUMNS_TOOLTIP'
          ),
          multiple: true,
          label: $translate.instant('Field.DIALOG.FORM.DISPLAY_COLUMNS'),
          options: [],
          onChange: function (value, options) {}
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            !scope.model.tableId ||
            (scope.model.type !== 'lookupSelect' &&
              scope.model.type !== 'modelSelect') ||
            scope.model.tableType === FieldUtilsService.lookupTypes.MODELS
          )
        },
        expressionProperties: {
          'templateOptions.options': ($viewValue, $modelValue, scope) => {
            if (scope.model.tableId) {
              return $scope.tablesFieldsOptions[scope.model.tableId]
            }
            return []
          },
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[18].templateOptions.disabled
          }
        }
      },
      // Multiple
      {
        key: 'multiple',
        type: 'checkbox',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.MULTI_SELECT'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.multiple = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'selectUser'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled || $scope.editMode
              ? true
              : scope.fields[19].templateOptions.disabled
          }
        }
      },
      // UPLOAD_MODE
      {
        key: 'mode',
        type: 'radioMaterial',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.UPLOAD_MODE'),
          options: [
            {
              value: 'full',
              name: $translate.instant('Field.DIALOG.FORM.MODE_SINGLE')
            },
            {
              value: 'multiple',
              name: $translate.instant('Field.DIALOG.FORM.MODE_MULTIPLE')
            }
          ],
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.mode = value
            if (value === 'multiple') {
              $scope.customFieldModel.runFileParser = false
              $scope.customFieldModel.fileParser = null
            }
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'upload'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled || $scope.editMode
              ? true
              : scope.fields[20].templateOptions.disabled
          }
        }
      },
      // HELP-IMAGE
      {
        key: 'helpImage',
        type: 'upload',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.HELP_MEDIA'),
          keyIs: 'id',
          mode: 'full',
          filetype: 'media',
          onChange: async function (value, options) {
            const resourceId = options.value()
            $scope.customFieldPreview[0].templateOptions.helpImage = resourceId
            if (resourceId) {
              Resource.findOne({
                filter: { where: { id: resourceId } }
              }).$promise.then(resource => {
                $scope.customFieldPreview[0].templateOptions.resource =
                  FormlyHelper.addResourceParametersToField(resource)
                console.log(resource)
              })
            }
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type === '' ||
            scope.model.type === 'subForm' ||
            scope.model.type === 'imageBasedForm'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[21].templateOptions.disabled
          }
        }
      },
      // RUN_FILE_PARSER
      {
        key: 'runFileParser',
        type: 'checkbox',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.RUN_FILE_PARSER'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.runFileParser = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'upload'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled ||
              $scope.customFieldModel.mode === 'multiple'
              ? true
              : scope.fields[22].templateOptions.disabled
          }
        }
      },
      // FILE_PARSER
      {
        key: 'fileParser',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          required: true,
          tooltip: $translate.instant('Field.DIALOG.FORM.FILE_PARSER_TOOLTIP'),
          label: $translate.instant('Field.DIALOG.FORM.FILE_PARSER'),
          options: fileParsers,
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.fileParser = value
            setLivePreviewData('upload')
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'upload' || !scope.model.runFileParser
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled ||
              $scope.customFieldModel.mode === 'multiple'
              ? true
              : scope.fields[23].templateOptions.disabled
          }
        }
      },
      // MIN_REQUIRED_ROWS
      {
        key: 'minRequiredRows',
        type: 'input',
        templateOptions: {
          type: 'number',
          min: 0,
          tooltip: $translate.instant(
            'Field.DIALOG.FORM.MIN_REQUIRED_ROWS_TOOLTIP'
          ),
          label: $translate.instant('Field.DIALOG.FORM.MIN_REQUIRED_ROWS')
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'subForm'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[24].templateOptions.disabled
          }
        }
      },
      // FIELDS
      {
        key: 'fields',
        type: 'repeatedList',
        templateOptions: {
          btnText: $translate.instant('WF.ADD'),
          label: 'title',
          title: $translate.instant('Field.DIALOG.FORM.FIELDS'),

          hideEdit: true,
          onAdd: function (addObject, index) {
            const selectedFields = $scope.customFieldModel.fieldIds || []
            $scope.addEditFields(selectedFields)
          },
          onRemove: function (removedObject, index) {
            $scope.customFieldModel.fieldIds.splice(index, 1)
            if ($scope.customFieldPreview[0].templateOptions.fields) {
              $scope.customFieldPreview[0].templateOptions.fields.splice(
                index,
                1
              )
            }
            setLivePreviewData($scope.customFieldPreview[0].type, true)
          },
          onSorted: function (newArray, oldIndex, newIndex) {
            $scope.customFieldModel.fieldIds = move(
              $scope.customFieldModel.fieldIds,
              oldIndex,
              newIndex
            )
            if ($scope.customFieldPreview[0].templateOptions.fields) {
              $scope.customFieldPreview[0].templateOptions.fields = move(
                $scope.customFieldPreview[0].templateOptions.fields,
                oldIndex,
                newIndex
              )
            }
            setLivePreviewData($scope.customFieldPreview[0].type, true)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type !== 'subForm' &&
            scope.model.type !== 'imageBasedForm'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[25].templateOptions.disabled
          }
        }
      },
      // REQUIRED
      {
        key: 'required',
        type: 'checkbox',
        className: 'layout-row',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.REQUIRED_FIELD'),
          options: [
            { name: $translate.instant('CONTENTPACKAGE.YES'), id: true },
            { name: $translate.instant('CONTENTPACKAGE.NO'), id: false }
          ],
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.required = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return (
            scope.model.type === '' ||
            scope.model.type === 'subForm' ||
            scope.model.type === 'imageBasedForm'
          )
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[26].templateOptions.disabled
          }
        }
      },
      // RESOURCE
      {
        key: 'resourceId',
        type: 'upload',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Field.DIALOG.FORM.RESOURCE'),
          keyIs: 'id',
          mode: 'full',
          filetype: 'image',
          onChange: function (value, options) {
            if (!$scope.customFieldPreview[0].templateOptions.images) {
              $scope.customFieldPreview[0].templateOptions.images = [
                {
                  resourceId: options.value(),
                  shapes: $scope.customFieldModel.shapes,
                  layers: $scope.customFieldModel.layers,
                  fieldIds: $scope.customFieldModel.fieldIds
                }
              ]
            } else {
              $scope.customFieldPreview[0].templateOptions.images[0].resourceId =
                options.value()
            }
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.type !== 'imageBasedForm'
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isDisabled
              ? true
              : scope.fields[27].templateOptions.disabled
          }
        }
      },
      // MIN
      {
        key: 'min',
        type: 'input',
        className: 'layout-column',
        templateOptions: {
          type: 'number',
          label: $translate.instant('Field.DIALOG.FORM.MIN'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.min = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.inputType !== 'number'
        }
      },
      // MIN
      {
        key: 'max',
        type: 'input',
        className: 'layout-column',
        templateOptions: {
          type: 'number',
          label: $translate.instant('Field.DIALOG.FORM.MAX'),
          onChange: function (value, options) {
            $scope.customFieldPreview[0].templateOptions.max = value
            setLivePreviewData($scope.customFieldPreview[0].type)
          }
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return scope.model.inputType !== 'number'
        }
      }
    ]
  }

  init()
}

module.exports = WorkflowCustomFieldsDialogController
