const angular = require('angular')
/** @ngInject */

function FileManagerService ($mdDialog, UploadService) {
  const openCameraCaptureDialog = function openCameraCaptureDialog (
    options,
    getBase64
  ) {
    return UploadService.openCameraCaptureDialog()
      .then(async encodedFile => {
        if (getBase64) {
          return encodedFile
        } else {
          return UploadService.uploadBase64File(encodedFile, options)
        }
      })
      .catch(err => {
        if (err) {
          console.error(err)
        }
      })
  }
  const openUploadMenu = function openUploadMenu (
    options = {},
    getBase64 = false
  ) {
    return $mdDialog
      .show({
        controller: 'UploadMenuDialogController',
        template: require('./upload-menu/upload-menu.dialog.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        multiple: true
      })
      .then(async ({ method }) => {
        if (method === 'camera') {
          const res = await openCameraCaptureDialog(options, getBase64)
          return res
        } else {
          return new Promise((resolve, reject) => {
            // Create a new file input element
            const fileInput = document.createElement('input')
            if (options.extensions) {
              fileInput.accept = options.extensions
                .split(',')
                .map(ext => `.${ext}`)
                .join(',')
            }
            fileInput.type = 'file'

            // Add an event listener to detect when a file is selected
            fileInput.addEventListener('change', event => {
              const selectedFile = event.target.files[0]
              if (getBase64) {
                // read the file using fs
                const reader = new FileReader() // eslint-disable-line no-undef
                reader.onload = function () {
                  resolve(reader.result)
                }
                reader.readAsDataURL(selectedFile)
              } else {
                resolve(
                  UploadService.uploadFile({
                    file: selectedFile,
                    ...options
                  }).finally(() => fileInput.remove())
                )
              }
            })
            // Trigger the file input dialog
            fileInput.click()
          })
        }
      })
      .catch(err => {
        if (err) {
          console.error(err)
        }
      })
  }
  const openFileManagerDialog = function openFileManagerDialog (options = {}) {
    const defaultOptions = {
      filetype: '',
      extensions: null,
      extendExtensions: null,
      mediatype: null,
      displayType: null,
      modelName: null,
      modelId: null
    }

    options = Object.assign(defaultOptions, options)
    return $mdDialog.show({
      clickOutsideToClose: false,
      template: require('./views/dialog.html'),
      fullscreen: true,
      multiple: true,
      locals: options,
      resolve: {
        resolvesFolders: Folder =>
          Folder.find({
            filter: { order: 'name', include: ['user'] }
          }).$promise,
        ResolvedLastSeen: LastSeenResource =>
          LastSeenResource.findRelatedLastSeen().$promise
      },
      controller: 'FileDialogController as vm',
      parent: 'body'
    })
  }

  return {
    openFileManagerDialog,
    openUploadMenu
  }
}

module.exports = FileManagerService
