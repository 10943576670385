import { stringToSlug } from 'app/helper'

const debug = require('debug')('nextplus:loginService')

/* global _ */
/** @ngInject */
function LoginService (
  UserModel,
  msNavigationService,
  $rootScope,
  $cookies,
  $window,
  $state,
  LanguageUtils,
  Setting,
  nextplusSocket,
  MultiTranslateService,
  Menus,
  PermissionUtils,
  FormUtils,
  htmlWork
) {
  const service = {}
  service.currentUser = null
  service.menuFixed = false
  service.login = function (opt, credentials) {
    return UserModel.login(opt, credentials)
  }

  service.isLoggedIn = function () {
    return service.currentUser !== null
  }
  const socketConnection = () => {
    if (service.currentUser && service.currentUser.accessTokenId) {
      debug('auth event')
      nextplusSocket.socket.emit('auth', service.currentUser.accessTokenId)
      nextplusSocket.socket.emit('clientId', $cookies.get('clientId'))
    }
    nextplusSocket.socket.on('logout', () => {
      service.logout(false, false)
    })
  }
  $rootScope.$on('socket:connected', () => {
    debug('socket:connected')
    socketConnection()
  })
  /* const setUserCertificates = async function setUserCertificates () {
    const certificateIds = await UserModel.getUserCertificates().$promise
    $rootScope.currentUser.certificateIds = certificateIds
  } */
  const getPrivateSettings = async function getPrivateSettings () {
    const privateSettings = await Setting.privateSettings().$promise
    const settingNames = Object.keys(privateSettings)
    for (let i = 0; i < settingNames.length; i++) {
      const setting = settingNames[i]
      $rootScope.appSettings[setting] = privateSettings[setting]
    }
    MultiTranslateService.onSettings()
    $rootScope.$broadcast('appSettings', $rootScope.appSettings)
    $rootScope.appSettings.menu = $rootScope.appSettings.menu.map(item =>
      MultiTranslateService.getForView(Menus, $rootScope.currentLang, item)
    )
    $rootScope.flattenMenuItems = _.cloneDeep(
      msNavigationService.getFlatNavigation()
    )

    // Sort forms with weight  by weight
    const weightForms = _.sortBy(
      $rootScope.appSettings.forms.filter(
        form => form.weight !== null && form.weight !== undefined
      ),
      'weight'
    )

    // Sort forms without weight by name
    const nonWeightForms = _.sortBy(
      $rootScope.appSettings.forms.filter(
        form => form.weight === null || form.weight === undefined
      ),
      form => form.name.toLowerCase()
    )

    $rootScope.appSettings.forms = weightForms.concat(nonWeightForms)
    $rootScope.appSettings.forms.forEach(form => {
      const canView = FormUtils.isPermit('view', form)
      if (canView) {
        $rootScope.msNavigationServiceProvider.saveItem(
          `apps.forms.form_${form.id}`,
          {
            title: htmlWork.htmlEncode(form.name),
            icon: form.icon,
            state: 'app.reports.form',
            stateParams: { id: form.id },
            weight:
              form.weight === null || form.weight === undefined
                ? Infinity
                : form.weight
          }
        )
      }
    })
    $rootScope.appSettings.menu.forEach(item => {
      try {
        const path =
          item.settings.parent && item.settings.parent !== 0
            ? item.settings.parent + '.' + item.id
            : 'apps.' + item.id

        const obj = {
          title: htmlWork.htmlEncode(item.title),
          icon: item.settings.icon,
          weight: isNaN(item.settings.weight) ? 10000 : item.settings.weight,
          hidden: item.settings.hidden === true,
          roles: item.settings.roles || [],
          rolesBlocklist: item.settings.rolesBlocklist || [],
          acls: []
        }

        if (item.settings.isPage === false) {
          if (item.settings.isExternal === true) {
            const target = item.settings.target_blank ? '_blank' : '_self'
            obj.state = `app.pages.external({externalUrl:"${item.settings.link}",target:"${target}"})`
          } else {
            obj.state = item.settings.state
            if (item.settings.params) {
              obj.state = `${obj.state}(${item.settings.params})`
            }
          }
        } else {
          if (item.settings.page) {
            obj.state = `app.pages.content({id:"${item.settings.page}"})`
          }
        }

        if (item.baseId) {
          const findElem = _.find($rootScope.flattenMenuItems, {
            _path: item.baseId
          })
          if (findElem) {
            findElem.icon = obj.icon
            findElem.weight = isNaN(obj.weight) ? findElem.weight : obj.weight
            findElem.hidden = obj.hidden
            findElem.roles = obj.roles
            findElem.rolesBlocklist = obj.rolesBlocklist
            delete findElem.children
            // console.log(`Add base item ${item.baseId}`, findElem)
            $rootScope.msNavigationServiceProvider.saveItem(
              item.baseId,
              findElem
            )
          }
        } else {
          // console.log(`Add item ${path}`, obj)
          $rootScope.msNavigationServiceProvider.saveItem(path, obj)
        }
      } catch (e) {
        console.log('Error setting menu item', {
          e,
          item,
          flattenMenuItems: $rootScope.flattenMenuItems
        })
      }
    })
    $rootScope.originalFlattenMenuItems = $rootScope.originalFlattenMenuItems
      ? _.cloneDeep($rootScope.originalFlattenMenuItems)
      : _.cloneDeep(msNavigationService.getFlatNavigation())
    service.fixMenu(service.currentUser)
  }
  service.initSocket = () => {
    nextplusSocket.angularSocket.on('connect', socketConnection)
  }
  service.setCurrentUser = function (user) {
    return new Promise(async resolve => {
      await nextplusSocket.promiseState
      nextplusSocket.angularSocket.emit('auth', user.accessTokenId)
      nextplusSocket.socket.emit('clientId', $cookies.get('clientId'))

      if ($rootScope.appSettings) {
        if (
          user.lang &&
          $rootScope.appSettings?.availableLanguages?.includes(user.lang)
        ) {
          LanguageUtils.language = user.lang
        }
      }
      const html = document.getElementsByTagName('html')[0]
      const classes = html.classList
      classes.forEach(className => {
        if (className.startsWith('role-')) {
          html.classList.remove(className)
        }
      })
      try {
        user.roles.forEach(role => {
          if (!['$everyone', '$authenticated'].includes(role.name)) {
            html.classList.add(`role-${stringToSlug(role.name)}`)
          }
        })
      } catch (e) {
        console.error(e)
      }
      user.fullName = user.firstName + ' ' + user.lastName
      if (!user.bookmarks) user.bookmarks = []
      // user.displayName = htmlWork.htmlEncode(user.displayName)
      service.currentUser = user
      $rootScope.currentUser = user
      await getPrivateSettings()
      service.setUserHomepage()
      service.setUserDateTimeFormat()
      if (window.Sentry) {
        let release =
          'nextplus-frontend@' + $rootScope.appSettings?.manifest?.commit
        if ($rootScope.appSettings?.manifest?.branch === 'master') {
          release =
            'nextplus-frontend@' + $rootScope.appSettings?.manifest?.version
        }
        window.Sentry.init({
          release,
          initialScope: {
            user: {
              id: user.id
            },
            tags: {
              hostname: window.location.hostname
            }
          }
        })
      }
      if (window.Intercom) {
        const intercomUser = {
          name: user.displayName,
          email: user.email,
          created_at: Math.floor(new Date(user.created).getTime() / 1000)
        }

        intercomUser.user_hash = user.intercomHash
        intercomUser['Is Editor'] = PermissionUtils.isPermit(
          'Workflow',
          'createWorkflow'
        )
        intercomUser['Server URL'] = window.location.origin
        intercomUser['Has AI'] =
          $rootScope.appSettings.aiLLMServiceEnabled === true
        intercomUser['Activation Required'] =
          $rootScope.appSettings.activationRequired === true
        intercomUser['Has SAML'] =
          $rootScope.appSettings['authentication-saml']?.enabled === true
        intercomUser['Has LDAP'] =
          $rootScope.appSettings['authentication-ldap']?.enabled === true
        intercomUser['Has Priority'] =
          $rootScope.appSettings.prioritySettings?.enabled === true
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'n7q224ih',
          alignment: LanguageUtils.dir === 'rtl' ? 'right' : 'left',
          ...intercomUser
        })
      }
      resolve()
    })
  }
  service.setUserDateTimeFormat = function () {
    const settingsDateTimeFormats = $rootScope.appSettings.dateTimeFormats
    if (service.currentUser && service.currentUser.dateTimeFormats) {
      $rootScope.currentUser.dateTimeFormats.date = $rootScope.currentUser
        .dateTimeFormats.date
        ? $rootScope.currentUser.dateTimeFormats.date
        : settingsDateTimeFormats.date
      $rootScope.currentUser.dateTimeFormats.time = $rootScope.currentUser
        .dateTimeFormats.time
        ? $rootScope.currentUser.dateTimeFormats.time
        : settingsDateTimeFormats.time
    } else {
      $rootScope.currentUser.dateTimeFormats = settingsDateTimeFormats
    }
  }
  service.setUserHomepage = function () {
    service.currentUser.homepage = $state.href('app.my-home', {})
    if (service.currentUser && service.currentUser.roles) {
      service.currentUser.roles.forEach(role => {
        if (role.url) {
          try {
            const roleUrl = new URL(role.url)
            if ($window.location.hostname === roleUrl.hostname) {
              service.currentUser.homepage = role.url
            }
          } catch (e) {
            service.currentUser.homepage = role.url
          }
        }
      })
    }
  }
  service.fixMenu = function (res) {
    if (res) {
      if (!service.menuFixed) {
        const flatMenuItems = _.cloneDeep($rootScope.originalFlattenMenuItems)
        _.each(flatMenuItems, function (item) {
          if (_.isUndefined(item.hidden)) item.hidden = false
          if (
            item.state === 'app.settings.replication-clients' &&
            $rootScope.appSettings.isslave
          ) {
            item.hidden = true
          }
          if (item.hidden) {
            msNavigationService.saveItem(item._path, item)
            return item.hidden
          }
          if (item.rolesBlocklist && item.rolesBlocklist.length > 0) {
            const userRoleIds = _.map($rootScope.currentUser.roles, 'id')
            /* const userRoleNames = _.map(
              $rootScope.currentUser.roles,
              'nicename'
            ) */
            const userInRoleById =
              _.intersection(userRoleIds, item.rolesBlocklist).length > 0
            /* const userInRoleName =
              _.intersection(userRoleNames, item.rolesBlocklist).length > 0 */
            if (userInRoleById) {
              item.hidden = true
              msNavigationService.saveItem(item._path, item)
              return item.hidden
            }
          }

          if (item.roles && item.roles.length > 0) {
            const userRoleIds = _.map($rootScope.currentUser.roles, 'id')
            const userRoleNames = _.map(
              $rootScope.currentUser.roles,
              'nicename'
            )
            const userInRoleById =
              _.intersection(userRoleIds, item.roles).length > 0
            const userInRoleName =
              _.intersection(userRoleNames, item.roles).length > 0
            if (!userInRoleById && !userInRoleName) {
              item.hidden = true
              msNavigationService.saveItem(item._path, item)
              return item.hidden
            }
          }
          if (item.acls) {
            const checkFn =
              item.aclsCondition && item.aclsCondition === 'or'
                ? _.some
                : _.every
            const aclForbidden = !checkFn(item.acls, acl => {
              return PermissionUtils.isPermit(acl.model, acl.method)
            })
            if (aclForbidden) {
              item.hidden = true
            }

            if (
              item.state === 'app.settings.replication-clients' &&
              $rootScope.appSettings.isslave
            ) {
              item.hidden = true
            }
            msNavigationService.saveItem(item._path, item)
          } else {
            if (
              item.state === 'app.settings.replication-clients' &&
              $rootScope.appSettings.isslave
            ) {
              item.hidden = true
            }
            msNavigationService.saveItem(item._path, item)
          }
        })
      }
      service.menuFixed = true
    }
  }
  service.getCurrentUserSingletonActivePromise = false
  service.getCurrentUserSingleton = function () {
    return new Promise(resolve => {
      if (!service.getCurrentUserSingletonActivePromise) {
        service.getCurrentUserSingletonActivePromise = service.getCurrentUser()
      }
      resolve(service.getCurrentUserSingletonActivePromise)
      service.getCurrentUserSingletonActivePromise.then(() => {
        service.getCurrentUserSingletonActivePromise = false
      })
    })
  }

  $rootScope.getCurrentUser = service.getCurrentUser = function (
    skipCache = false
  ) {
    return new Promise(async resolve => {
      try {
        let cachedUser = service.currentUser
        if (cachedUser === null || !cachedUser.id || skipCache === true) {
          cachedUser = await UserModel.current().$promise
        }
        if (
          cachedUser.id &&
          (service.currentUser === null || !service.currentUser.id)
        ) {
          await service.setCurrentUser(cachedUser)
        }
        resolve(cachedUser)
      } catch (ex) {
        resolve(null)
      }
    })
  }

  service.changePassword = function () {
    return new Promise(async resolve => {
      if (service.currentUser && service.currentUser.id) {
        service.currentUser.resetPassword = false
      }
      resolve()
    })
  }

  $rootScope.logout = service.logout = async function (
    setNewPassword = false,
    serverLogout = true
  ) {
    if ($rootScope.sessionLogout) {
      await $rootScope.sessionLogout()
    }
    let promise = Promise.resolve()
    if (serverLogout) {
      promise = UserModel.logoutResolver({}).$promise
    }
    $rootScope.notifications = []
    nextplusSocket.angularSocket.emit('unauth')
    promise.then(function (res) {
      ClearLocalCredentials()
      if (res && res.samlLogoutUrl) {
        $window.location = res.samlLogoutUrl
      } else {
        $state.go('app.login', { setNewPassword })
      }
    })
    promise.catch(() => {
      $window.location.reload()
    })
  }
  service.hasRole = function (roles) {
    let result = false
    if (
      _.isUndefined($rootScope.currentUser) ||
      $rootScope.currentUser === null
    ) {
      return false
    }
    if (
      !$rootScope.currentUser.roles ||
      $rootScope.currentUser.roles.length === 0
    ) {
      return false
    }
    _.each(roles, function (role) {
      const hasRole = _.find($rootScope.currentUser.roles, { name: role })
      if (hasRole) {
        result = true
      }
    })
    return result
  }

  function ClearLocalCredentials () {
    service.menuFixed = false
    $cookies.remove('authorization')
    service.currentUser = null
    $rootScope.currentUser = null
    return true
  }

  return service
}

module.exports = LoginService
