'use strict'
/* global _ $ */

/** @ngInject */
function filePicker ($timeout) {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: require('./filePicker.html'),
    scope: {
      fileChange: '=',
      mode: '@?',
      displayType: '@?',
      text: '@',
      ngModel: '=',
      object: '=?',
      disabled: '=?',
      id: '@?',
      container: '=?',
      modelName: '=?',
      modelId: '=?',
      keepOriginal: '=?',
      uniqueFiles: '='
    },
    replace: false,
    link: function (scope, element, attrs, ngModel) {
      scope.text = attrs.text
      if (attrs.id) scope.id = attrs.id
      scope.mode = attrs.mode || 'mini'
      scope.filetype = attrs.filetype
      scope.extensions = attrs.extensions
      scope.extendExtensions = attrs.extendExtensions
      scope.mediatype = attrs.mediatype || ''
      scope.formIndex = attrs.formIndex || ''
      scope.fileChangeFn = attrs.fileChange || ''

      scope.renderValue = function (items) {
        if (items?.length > 0) {
          if (scope.mode === 'multiple') {
            ngModel.$setViewValue(items.map(item => item.id))
          } else {
            ngModel.$setViewValue(items[0].id)
          }
        } else {
          if (scope.mode === 'multiple') {
            ngModel.$setViewValue([])
          } else {
            ngModel.$setViewValue(null)
          }
        }

        ngModel.$render() // depends – if you want update input value or only model value in the scope
      }
      scope.fileChangeUpdate = async function (result) {
        scope.renderValue(result)
        if (!scope.fileChange) {
          if (scope.$root[scope.fileChangeFn]) {
            await scope.$root[scope.fileChangeFn](result)
          }
        } else {
          await scope.fileChange(result)
        }
      }

      if (typeof attrs.text === 'undefined') scope.text = 'MEDIA'
    },
    /** @ngInject */
    controller: function (
      $scope,
      $rootScope,
      $mdDialog,
      $mdToast,
      $translate,
      DialogService,
      getUrlFromObj,
      Resource,
      UploadService,
      FileManagerService
    ) {
      $scope.selectedFiles = $scope.mode === 'multiple' ? [] : null
      $scope.getUrlFromObj = getUrlFromObj
      $scope.uploadProcess = false
      $scope.shouldOpenCustomMenu = false

      UploadService.canStreamVideo().then(bool => {
        $scope.shouldOpenCustomMenu = bool
      })

      $scope.$watch('object', function (newValue, oldValue, scope) {
        if (
          !newValue ||
          (Object.keys(newValue).length === 0 &&
            newValue.constructor === Object) ||
          newValue.length === 0
        ) {
          $scope.selectedFiles = []
        } else if ($scope.selectedFiles) {
          if (_.isArray(newValue)) {
            $scope.selectedFiles = newValue
          } else {
            $scope.selectedFiles = [newValue]
          }
        }
        $scope.$applyAsync()
      })

      if ($scope.ngModel) {
        if (
          ($scope.mode === 'multiple' && $scope.object?.length > 0) ||
          ($scope.mode !== 'multiple' && $scope.object)
        ) {
          if (_.isArray($scope.object)) {
            $scope.selectedFiles = $scope.object
          } else {
            $scope.selectedFiles = [$scope.object]
          }
        } else {
          let findQuery = {
            id: $scope.ngModel
          }
          if ($scope.mode === 'multiple') {
            findQuery = {
              id: {
                inq: $scope.ngModel
              }
            }
          }
          Resource.find({
            filter: { where: findQuery }
          }).$promise.then(data => {
            if (data?.length > 0) {
              if ($scope.mode === 'multiple') {
                $scope.selectedFiles = data
              } else {
                $scope.selectedFiles = [data[0]]
              }
            } else {
              $scope.selectedFiles = []
            }
            $scope.fileChangeUpdate($scope.selectedFiles)
          })
        }
      }

      $scope.removeImage = function removeImage (id, event) {
        event.preventDefault()
        event.stopPropagation()
        event.cancelBubble = true
        const index = $scope.selectedFiles.findIndex(file => file.id === id)
        if (index > -1) {
          DialogService.deleteDialog(
            $translate.instant(
              'COMMON.ARE_YOU_SURE',
              {},
              null,
              null,
              'sceParameters'
            )
          ).then(
            function () {
              $scope.selectedFiles.splice(index, 1)
              $scope.object = $scope.selectedFiles
              $scope.fileChangeUpdate($scope.selectedFiles)
            },
            function () {}
          )
        }
      }

      $scope.uploadFileDirectly = function uploadFileDirectly (file, errFiles) {
        const selectedFile = file
        if (selectedFile) {
          const file = $scope.mediatype
            ? { file: selectedFile, mediatype: $scope.mediatype }
            : { file: selectedFile }
          file.folderId = null
          file.keepOriginal = _.isNil($scope.keepOriginal)
            ? true
            : $scope.keepOriginal
          file.hidden = true
          file.container = $scope.container
          $rootScope.loadingProgress = true
          $scope.uploadProcess = true
          UploadService.uploadFile(file).then(
            async function (res) {
              if (res.data && _.isArray(res.data)) {
                if ($scope.mode === 'multiple') {
                  if (!$scope.selectedFiles) {
                    $scope.selectedFiles = []
                  }
                  $scope.selectedFiles.push(...res.data)
                } else {
                  $scope.selectedFiles = res.data
                }
                $scope.object = $scope.selectedFiles
                try {
                  await $scope.fileChangeUpdate($scope.selectedFiles)
                  $scope.renderValue($scope.selectedFiles)
                } catch (err) {
                  $scope.renderValue([])
                }
              }
              $rootScope.loadingProgress = false
              $scope.uploadProcess = false
              $scope.$applyAsync()
            },
            function (res) {
              if (res.status > 0) {
                const errorToast = $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: 'document.body',
                  theme: 'error-toast',
                  hideDelay: 3000
                })
                console.log(res.status + ': ' + res.data)
                if (res.status === 413) {
                  $mdToast.updateTextContent(
                    $translate.instant('COMMON.ERRORS.UPLOAD_413')
                  )
                }
                if (res.status === 500) {
                  $mdToast.updateTextContent(
                    $translate.instant('COMMON.ERRORS.UPLOAD_500')
                  )
                }
                $mdToast.show(errorToast)
              }
              $rootScope.loadingProgress = false
              $scope.uploadProcess = false
              $scope.$applyAsync()
            },
            function (evt) {
              // for git changes
              const progressPercentage = parseInt(
                (100.0 * evt.loaded) / evt.total
              )
              $scope.determinateValue = progressPercentage
            }
          )
        }
      }

      $scope.picker = {
        open: function (ev) {
          if ($scope.shouldOpenCustomMenu && $scope.displayType === 'upload') {
            FileManagerService.openUploadMenu().then(this.closeDialog)
          } else {
            this.openUploadMethod(ev)
          }
        },
        openUploadMethod: function (ev) {
          if ($scope.displayType === 'upload') {
            $timeout(function () {
              $(ev.target)
                .parents('file-picker')
                .find('.upload-file-button')
                .click()
            }, 100)
          } else {
            FileManagerService.openFileManagerDialog({
              filetype: $scope.filetype,
              extensions: $scope.extensions,
              extendExtensions: $scope.extendExtensions,
              mediatype: $scope.mediatype,
              displayType: $scope.displayType,
              modelName: $scope.modelName || null,
              modelId: $scope.modelId || null
            })
              .then(this.closeDialog)
              .catch(err => {
                if (err) {
                  console.log(err)
                }
              })
          }
        },
        closeDialog: function (result) {
          if (result.data) result = result.data
          if (!$scope.selectedFiles) {
            $scope.selectedFiles = []
          }
          if ($scope.mode === 'multiple') {
            if ($scope.uniqueFiles && !checkIfSelectedFileIsUnique(result[0])) {
              const errorToast = $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'document.body',
                theme: 'error-toast',
                hideDelay: 3000
              })
              $mdToast.updateTextContent(
                $translate.instant('COMMON.ERRORS.FILE_ALREADY_SELECTED')
              )

              $mdToast.show(errorToast)
            } else {
              $scope.selectedFiles.push(...result)
            }
          } else {
            $scope.selectedFiles = result
          }
          $scope.object = $scope.selectedFiles
          $scope.fileChangeUpdate($scope.object)
        }
      }
      const checkIfSelectedFileIsUnique = file => {
        return $scope.selectedFiles.findIndex(f => f.id === file.id) === -1
      }
      $scope.previewLink = image => {
        return `./api/containers/${image.container}/download/${image.name}`
      }
    }
  }
}

module.exports = filePicker
